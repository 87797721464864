import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71')
];

export const server_loads = [0,9,11];

export const dictionary = {
		"/(default)": [~49,[10]],
		"/(default)/artworks": [55,[10]],
		"/(auth)/artworks/create": [17,[2]],
		"/(default)/artworks/[artwork_id]": [56,[10]],
		"/(auth)/characters/create": [18,[2]],
		"/(auth)/characters/profile": [19,[2]],
		"/(auth)/characters/settings/[id]": [20,[2]],
		"/(auth)/chats": [22,[2,3]],
		"/(auth)/chats/[character_id]": [23,[2,3,4]],
		"/(auth)/chats/[character_id]/[chat_id]": [24,[2,3,4]],
		"/(auth)/chat": [21,[2]],
		"/(console)/console": [~42,[9]],
		"/(console)/console/artworks": [43,[9]],
		"/(console)/console/banners": [44,[9]],
		"/(console)/console/characters": [45,[9]],
		"/(console)/console/features": [46,[9]],
		"/(console)/console/reports": [47,[9]],
		"/(console)/console/settings": [48,[9]],
		"/(default)/forgot-password": [57],
		"/invite": [71],
		"/(default)/i": [~58,[10,13]],
		"/(default)/i/[artwork_id]": [59,[10,13]],
		"/(default)/i/[artwork_id]/comments": [60,[10,13]],
		"/(auth)/notifications": [25,[2]],
		"/(auth)/pricing": [26,[2]],
		"/(default)/privacy": [61,[10]],
		"/(auth)/progress": [27,[2,5]],
		"/(auth)/progress/achievements": [28,[2,5]],
		"/(auth)/progress/quests": [29,[2,5]],
		"/(auth)/progress/store": [30,[2,5]],
		"/(auth)/progress/tasks": [31,[2,5]],
		"/(auth)/quest": [32,[2]],
		"/(default)/signin": [62],
		"/(default)/signup": [63],
		"/(auth)/subscription/summary": [33,[2]],
		"/(default)/support": [64,[10]],
		"/(default)/tos": [65,[10]],
		"/(auth)/user/profile": [34,[2,6]],
		"/(auth)/user/profile/badges": [35,[2,6]],
		"/(auth)/user/profile/c": [36,[2,6]],
		"/(auth)/user/profile/i": [37,[2,6,7]],
		"/(auth)/user/profile/i/[artwork_id]": [38,[2,6,7]],
		"/(auth)/user/profile/subscriptions": [39,[2,6]],
		"/(auth)/user/settings": [40,[2,8]],
		"/(auth)/user/settings/[id]": [41,[2,8]],
		"/(default)/u": [66,[10,14]],
		"/(default)/u/[username]": [67,[10,14,15]],
		"/(default)/u/[username]/badges": [68,[10,14,15]],
		"/(default)/u/[username]/i": [69,[10,14,15,16]],
		"/(default)/u/[username]/i/[artwork_id]": [70,[10,14,15,16]],
		"/(default)/[username]": [50,[10,11]],
		"/(default)/[username]/badges": [52,[10,11]],
		"/(default)/[username]/f": [53,[10,11,12]],
		"/(default)/[username]/f/[artwork_id]": [54,[10,11,12]],
		"/(default)/[username]/[artwork_id]": [51,[10,11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';